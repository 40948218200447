.dashboard {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .dashboard-content {
    display: flex;
    flex-grow: 1;
  }
  
  .dashboard-main {
    flex-grow: 1;
  }
  