.sticky-menu {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .sticky-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sticky-menu ul li a {
    display: block;
    padding: 1rem;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .sticky-menu ul li a:hover {
    color: #666;
  }
  
  .signin-button button {
    background-color: #f50057;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .signin-button button:hover {
    background-color: #d4004f;
  }
  
  .content {
    margin-top: 100px;
    text-align: center;
  }
  
  .content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .content p {
    font-size: 1.5rem;
  }
  .sign-in-button {
    background-color: #ff6600;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
    text-decoration: none;
  }
  
  .sign-in-button:hover {
    background-color: #ff3300;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  